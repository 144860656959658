export function useDataFormatter (): {
  perksFormatter: (a: string[]) => string,
  } {
  const perksFormatter = (perks: string[]) => {
    return perks.join(' · ');
  };

  return {
    perksFormatter,
  };
}
