<template>
  <TooltipProvider>
    <TooltipRoot>
      <TooltipTrigger>
        <Component
          :is="computedLabel"
          :class="computedSizes"
          v-bind="$attrs"
        />
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent
          as-child
          class="text-brand-white data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade select-none rounded-md px-3 py-2 text-sm leading-none shadow-2xl will-change-[transform,opacity] bg-brand-secondary"
          :side-offset="5"
        >
          <ul>
            {{ $t('label') }}: {{ labelType }}
            <TooltipArrow class="fill-brand-secondary" size="8" />
          </ul>
        </TooltipContent>
      </TooltipPortal>
    </TooltipRoot>
  </TooltipProvider>
</template>

<script setup lang="ts">
import { TooltipPortal, TooltipArrow, TooltipContent, TooltipProvider, TooltipRoot, TooltipTrigger } from 'radix-vue';
import { computed, defineAsyncComponent } from 'vue';
import type { EcoLabels } from '~/types';

interface Props {
  labelType: EcoLabels | null
  size?: 'md' | 'xs'
}

defineOptions({
  name: 'EcoLabel',
  inheritAttrs: false,
});

const allowedEcoLabels: EcoLabels[] = ['0', 'A', 'B', 'C', 'Eco'];
const props = withDefaults(defineProps<Props>(), {
  size: 'xs',
});

const computedSizes = computed(() => {
  const mapping = {
    md: 'w-6 h-6 lg:w-12 lg:h-12',
    xs: 'w-6 h-6',
  };

  return mapping[props.size];
});
const computedLabel = computed(() => {
  if (!props.labelType || !allowedEcoLabels.includes(props.labelType)) {
    return 'span';
  }

  let labelKey = props.labelType;
  if (props.labelType === 'Eco') {
    labelKey = 'A';
  }

  return defineAsyncComponent(() => import(`./Eco-${labelKey}.vue`));
});
</script>
